/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";
import { greenKanhaPackageData } from "../../data/donation-packages";

const GreenKanha = () => {
  const packageData = greenKanhaPackageData;

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Donation_for_Green_Kanha", event),
    []
  );

  const treePackage =
    '<div class="container"><div class="tree_package"><donation_package type=both/></div></div><div class="container"><div id="tree_package" class="col-12"><gatsby_donation donationId = "11" amount=0 rate=1000 btntext = "Contribute Now" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Donation for Green Kanha" allowOverseas = false/></div>';

  const renderContentHeader = () => (
    <Box
      sx={{
        backgroundColor: "rgb(223, 242, 246)",
        textAlign: "center!important",
        marginBottom: "30px",
        padding: "40px 0px",
        border: "1px solid rgb(211, 213, 210)",
      }}
    >
      <h1 sx={{ fontSize: "1.7em" }}>
        “EACH ONE, TEACH ONE, HOW TO PLANT ONE”
      </h1>
    </Box>
  );
  return (
    <LayoutHome
      seoTitle="Heartfulness Donations"
      contentHeader={renderContentHeader()}
    >
      <Grid columns={1}>
        <Box>
          <h3
            sx={{
              fontSize: "1.6em",
              marginBottom: "0.5rem",
              fontWeight: "500",
              lineHeight: "1.2",
            }}
          >
            The Green Kanha Initiative
          </h3>
          <h4
            sx={{
              color: "rgb(12, 152, 167)",
              marginBottom: "0.5rem",
              fontWeight: "500",
              lineHeight: "1.2",
            }}
          >
            An invitation from Daaji
          </h4>
          <p
            sx={{
              fontSize: "18px",
              marginBottom: "1rem",
              fontWeight: "500",
              lineHeight: "1.5",
            }}
          >
            <b>“Why must we have greener Kanha?</b>
          </p>
          <p
            sx={{
              fontSize: "18px",
              marginBottom: "1rem",
              fontWeight: "500",
              lineHeight: "1.5",
            }}
          >
            Each time we come here, we must feel that Kanha is greener than our
            previous visit, just as our spiritual condition should be – better
            each time. I am delighted to share some excerpts from Complete{" "}
            <i>Works of Param Pujya Lalaji Maharaj.</i>
          </p>
          <p
            sx={{
              fontSize: "18px",
              marginBottom: "1rem",
              fontWeight: "500",
              lineHeight: "1.5",
            }}
          >
            See how he conveys to us with so much passion about the trees and
            plants who can retain truth, the divine charge, and transmission for
            long durations.
          </p>
          <p
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "1.5",
            }}
          >
            Let us make Kanha Shanti Vanam the realization of his dream, by
            adopting as many trees as possible. “Donation to this fund is
            eligible for 80G exemption under the Income Tax Act, India.
          </p>
        </Box>
        <Box>
          <div>
            <div>
              <div
                sx={{
                  backgroundColor: "#e4e2e2",
                  padding: "20px",
                  marginBottom: "15px",
                }}
              >
                <div sx={{ border: "3px solid #ffffff", padding: "10px" }}>
                  <div
                    sx={{
                      color: "#0c98a7",
                      fontFamily: "gotham-book",
                      paddingBottom: "10px",
                    }}
                  >
                    <p>
                      From Complete Works Ram Chandra – Lalaji Volume II
                      Page:125-126
                    </p>
                  </div>
                  <div>
                    <p>
                      “Rawati is a tribal area in Malwa. It has a natural beauty
                      and I had an opportunity to go to this place to visit some
                      of my beloved associate brothers. One of my brothers by
                      name Dr. Krishnaswaroop was employed as government medical
                      officer there, and Shri Heeralal and Revashankar were
                      employees of the same hospital. They are very dear to me.
                      At their request I went to Rawati.
                    </p>
                    <p>
                      The natural beauty, and the innocent people of the village
                      were the attractions. I liked this place very much. I felt
                      I should stay there forever. I am in the latterhalf of my
                      life. Instead of helping people to know the grace of God
                      and His love, this place, its trees, plants,earth, water,
                      made me get interested in the naturalscenery. I was eager
                      to make my dream come true of building an ashram like that
                      of Kakabhushundi. When compared to people, such places
                      have more power to attract one’s feelings, and have power
                      of transmission. Such places have the capacity of
                      protecting the Truth for a longer duration, when compared
                      to other places.As a result, whoever comes in contact with
                      such places cannot but be influenced. This is the reason
                      why even today this place looks like Braj (Brindavan).
                      Even if one looks at it from the traditional angle, one
                      can do better sadhana here than at other places.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <Box>
          <div sx={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <PageContent
                eventCallback={eventCallback}
                pageContent={treePackage}
                packageData={packageData}
              />
            </div>
          </div>
        </Box>
        <Box>
          <p>
            <b>Please join this movement:</b>
          </p>
          <p>– Sponsor a sapling/tree/group of trees</p>
          <p>– Letter of appreciation from Daaji will be sent to everyone</p>
          <p>– Adopting mini Forests/clusters will be given recognition</p>
          <p>– Visit your trees in Kanha Shanti Vanam!</p>
        </Box>
      </Grid>
    </LayoutHome>
  );
};

export default GreenKanha;
